const getterNames = Object.freeze({
    // TODO add getter names
    loading: 'GET_LOADING',
    getSelectedUnitStandard: 'GET_SELECTED_UNIT_STANDARD',
    getSearchedUnitStandard: 'GET_SEARCHED_UNIT_STANDARD',
} as const);

const mutationNames = Object.freeze({
    // TODO add mutation names
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
    setSelectedUnitStandard: 'SET_SELECTED_UNIT_STANDARD',
    setSearchedUnitStandard: 'SET_SEARCHED_UNIT_STANDARD',
    setUnitStandardCodeToSearch: 'SET_UNIT_STANDARD_CODE_TO_SEARCH',
} as const);

const actionNames = Object.freeze({
    // TODO add action names
    fetchUnitStandard: 'FETCH_UNIT_STANDARD',
    searchForUnitStandard: 'SEARCH_FOR_UNIT_STANDARD',
} as const);

const namespace = 'unitStandardState';

export { getterNames, mutationNames, actionNames, namespace };
