


























































































































import {
    defineComponent,
    reactive,
    toRefs,
    computed,
} from '@vue/composition-api';
import CnTextField from '@/components/Cn-TextField.vue';
import CnDateField from '@/components/Cn-DateField.vue';
import CnButton from '@/components/Cn-Button.vue';
import CnSelect from '@/components/Cn-Select.vue';
export default defineComponent({
    name: 'CreateNewTrainee',
    emits: ['onCreateTrainee'],
    components: {
        CnTextField,
        CnDateField,
        CnButton,
        CnSelect,
    },
    props: {
        errorMessages: {
            type: [],
            required: false,
            default: [],
        },
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props, context) {
        // #region hooks
        // #endregion

        // #region data
        const genders = Object.freeze({
            Male: 1,
            Female: 2,
            Unknown: 3,
        } as const);

        const state = reactive({
            fName: null as string | null,
            lName: null as string | null,
            dob: null as string | null,
            gender: '' as any,
            selectedFile: undefined as File | undefined,
            genderItems: Object.keys(genders).map((key) => ({
                text: key,
                value: genders[key as keyof typeof genders],
            })),
        });
        // #endregion

        // #region methods
        const chooseFiles = () => {
            document.getElementById('fileUpload')?.click();
        };
        const handleSelectedFiles = (e: Event) => {
            state.selectedFile = undefined;
            e.preventDefault();
            const files = (e.target as HTMLInputElement).files;
            if (files != undefined && files?.length > 0) {
                if (files[0].size <= 1024 * 1024 * 2) {
                    state.selectedFile = files[0];
                } else {
                    alert('File size cannot exceed more than 2 MB');
                }
            }
        };
        const getFileSizeString = (size: number) => {
            if (size < 1024) {
                return `${size} bytes`;
            } else if (size > 1024 && size <= 1024 * 1024) {
                return `${(size / 1024).toFixed(2)} KB`;
            } else {
                return `${(size / (1024 * 1024)).toFixed(2)} MB`;
            }
        };

        const createNewTrainee = () => {
            const data: FormData = new FormData();
            data.append('fName', state.fName as string);
            data.append('lName', state.lName as string);
            data.append('dob', state.dob as string);
            data.append('gender', state.gender as string);
            data.append(
                'proofFile',
                state.selectedFile as File,
                `ProofOfIdentity - ${state.selectedFile?.name}`,
            );
            context.emit('onCreateTrainee', data);
        };
        // #endregion

        // #region lifeCycle
        // #endregion

        return {
            ...toRefs(state),
            chooseFiles,
            handleSelectedFiles,
            getFileSizeString,
            createNewTrainee,
        };
    },
});
