const getterNames = Object.freeze({
    // TODO add getter names
} as const);

const mutationNames = Object.freeze({
    // TODO add mutation names
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
    setSelectedUnitStandardVersion: 'SET_SELECTED_UNIT_STANDARD_VERSION',
    setUnitStandardVersions: 'SET_UNIT_STANDARD_VERSIONS',
} as const);

const actionNames = Object.freeze({
    // TODO add action names
    fetchUnitStandardVersions: 'FETCH_UNIT_STANDARD_VERSIONS',
} as const);

const namespace = 'unitStandardVersionsState';

export { getterNames, mutationNames, actionNames, namespace };
