




























import {
    defineComponent,
    reactive,
    toRefs,
    computed,
    PropType,
    ComponentInstance,
    ref,
} from '@vue/composition-api';
import { ITrainingPlanUnitStandard } from '@/api';
import { useVuexStore } from '@/hooks/useVuexStore';
import {
    mutationNames as EnterResultsMutations,
    namespace as EnterResultsNamespace,
} from '@/store/modules/enterResults/EnterResults';
import CnDataTable from '@/components/Cn-DataTable.vue';
import ResultRow from './ResultRow.vue';
import { VForm } from 'vuetify/lib/components';
import { DataPagination } from 'vuetify/types';

type VFormComponent = ComponentInstance & { validate: () => boolean };

export default defineComponent({
    name: 'ResultsStep',
    components: { CnDataTable, ResultRow, VForm },
    props: {
        trainingPlanId: {
            type: String,
        },
        unitStandards: {
            type: Array as PropType<ITrainingPlanUnitStandard[]>,
            required: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, context) {
        // #region hooks
        const { commit } = useVuexStore(context);
        // #endregion

        // #region data
        const form = ref<VFormComponent | null>(null);
        const state = reactive({
            headers: computed(() => {
                const header = {
                    text: props.trainingPlanId,
                    align: 'start',
                    value: 'trainingPlanUnitStandards',
                    sortable: false,
                };
                return [header];
            }),
        });
        const pagination = ref<DataPagination>();
        // #endregion

        // #region computed

        const currentPageOffset = computed<number>(() => {
            if (pagination?.value) {
                return (pagination.value.page - 1) * pagination.value.itemsPerPage;
            }
            else {
                return 0;
            }
        });

        // #endregion

        // #region methods
        const mutateTrainingPlanUnitStandard = (
            unitStandard: ITrainingPlanUnitStandard,
            index: number,
        ) => {
            commit(
                `${EnterResultsNamespace}/${EnterResultsMutations.setUnitStandard}`,
                { unitStandard, index },
            );
        };

        const handleAchievedChanged = (value: string, index: number): void => {
            const offsetIndex = currentPageOffset.value + index;
            
            const updatedUnitStandard = {
                ...props.unitStandards[offsetIndex],
                dateAchieved: value,
            } as ITrainingPlanUnitStandard;
            mutateTrainingPlanUnitStandard(updatedUnitStandard, offsetIndex);
        };
        const handleIsRccChanged = (value: boolean, index: number): void => {
            const offsetIndex = currentPageOffset.value + index;
            
            const updatedUnitStandard = {
                ...props.unitStandards[offsetIndex],
                isRcc: value,
            } as ITrainingPlanUnitStandard;
            mutateTrainingPlanUnitStandard(updatedUnitStandard, offsetIndex);
        };
        const handleInput = (value: boolean): void => {
            context.emit('input', value);
        };

        const validate = (): boolean => {
            return form.value?.validate() ?? false;
        };

        const handlePagination = (event: DataPagination) => {
            pagination.value = event;
        };
        // #endregion

        // #region lifeCycle
        // #endregion

        return {
            ...toRefs(state),
            form,
            handleAchievedChanged,
            handleIsRccChanged,
            handleInput,
            validate,
            handlePagination,
        };
    },
});
