import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '@/views/EnterResults.vue';
import { registerGuard } from './Guard';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    // -- DO NOT REMOVE -- AUTOMATED ROUTE IMPORTS
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    // {
    //     path: '/my-training-plans',
    //     name: 'My Training Plans',
    //     component: () => import('../views/MyTrainingPlans.vue'),
    //     meta: {
    //         requiresAuth: true,
    //     },
    // },
    {
        path: '/my-scope',
        name: 'My Scope',
        component: () => import('../views/MyScope.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/logout',
        name: 'Logout',
        component: () => import('../views/Logout.vue'),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/failed',
        name: 'Login Failed',
        component: () => import('../views/Failed.vue'),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/enter-result',
        name: 'Enter Results',
        component: () => import('../views/EnterResults.vue'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/results',
        name: 'ResultsEntered',
        component: () => import('../views/ResultsEntered.vue'),
    },
    {
        path: '/result-entered-confirm',
        name: 'ResultEnteredConfirm',
        component: () => import('../views/ResultEnteredConfirm.vue'),
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

registerGuard(router);

export default router;
