import {
    InteractionStatus,
    AccountInfo,
    AuthenticationResult,
} from '@azure/msal-browser';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { GetterNames, MutationNames } from './types';
// -- DO NOT REMOVE -- AUTOMATED MODULE IMPORTS
import account, {
    AccountState,
    namespace as accountNamespace,
} from './modules/account/Account';
import trainee, {
    TraineeState,
    namespace as traineeNamespace,
} from './modules/trainee/Trainee';
import CreateResult, {
    CreateResultState,
    namespace as CreateResultNamespace,
} from './modules/createResult/CreateResult';
import enterResults, {
    EnterResultsState,
    namespace as enterResultsNamespace,
} from './modules/enterResults/EnterResults';
import unitStandardVersions, {
    UnitStandardVersionsState,
    namespace as unitStandardVersionsNamespace,
} from './modules/unitStandardVersions/UnitStandardVersions';
import unitStandard, {
    UnitStandardState,
    namespace as unitStandardNamespace,
} from './modules/unitStandard/UnitStandard';
import resultsEntered, {
    ResultsEnteredState,
    namespace as resultsEnteredNamespace,
} from './modules/resultsEntered/ResultsEntered';
import myScope, {
    MyScopeState,
    namespace as myScopeNamespace,
} from './modules/myScope/MyScope';
import myTrainingPlans, {
    MyTrainingPlansState,
    namespace as myTrainingPlansNamespace,
} from './modules/myTrainingPlans/MyTrainingPlans';

Vue.use(Vuex);

export type RootState = {
    // module state
    auth: {
        result: AuthenticationResult | null;
        inProgress: InteractionStatus;
        accounts: AccountInfo[];
    };
    // -- DO NOT REMOVE -- AUTOMATED MODULE TYPES
    [accountNamespace]?: AccountState;
    [traineeNamespace]?: TraineeState;
    [CreateResultNamespace]?: CreateResultState;
    [enterResultsNamespace]?: EnterResultsState;
    [unitStandardVersionsNamespace]?: UnitStandardVersionsState;
    [unitStandardNamespace]?: UnitStandardState;
    [resultsEnteredNamespace]?: ResultsEnteredState;
    [myScopeNamespace]?: MyScopeState;
    [myTrainingPlansNamespace]?: MyTrainingPlansState;
};

export const defaultStore = (): StoreOptions<RootState> => ({
    state() {
        return {
            auth: {
                result: null,
                inProgress: InteractionStatus.Startup,
                accounts: [],
            },
        };
    },
    getters: {
        [GetterNames.accessToken]: (state) => state.auth.result?.accessToken,
        [GetterNames.signedIn]: (state) =>
            state.auth.inProgress === InteractionStatus.None,
        [GetterNames.name]: (state) => state.auth.result?.account?.name,
    },
    mutations: {
        [MutationNames.setAuthenticationResult](state, result) {
            state.auth.result = result;
        },
        [MutationNames.setInProgress](state, interactionStatus) {
            state.auth.inProgress = interactionStatus;
        },
        [MutationNames.setAccounts](state, accounts) {
            state.auth.accounts = accounts;
        },
    },
    actions: {},
    modules: {
        // -- DO NOT REMOVE -- AUTOMATED MODULE REGISTRATIONS
        [accountNamespace]: account,
        [traineeNamespace]: trainee,
        [CreateResultNamespace]: CreateResult,
        [enterResultsNamespace]: enterResults,
        [unitStandardVersionsNamespace]: unitStandardVersions,
        [unitStandardNamespace]: unitStandard,
        [resultsEnteredNamespace]: resultsEntered,
        [myScopeNamespace]: myScope,
        [myTrainingPlansNamespace]: myTrainingPlans,
    },
});

const store = new Vuex.Store<RootState>(defaultStore());

export default store;
