import VueCompositionApi, {
    createApp,
    defineComponent,
} from '@vue/composition-api';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import MSALplugin from './plugins/msal';
import { msalInstance } from './authConfig';

import './styles/rootoverrides.scss';
import './styles/main.scss';

Vue.config.productionTip = false;

Vue.use(VueCompositionApi);

const app = createApp(
    defineComponent({
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }),
);
app.use(MSALplugin, msalInstance);
app.mount('#app');
