




































import { defineComponent, computed, ref, reactive } from '@vue/composition-api';

import { VTextField } from 'vuetify/lib/components';
import CnButton from '@/components/Cn-Button.vue';

const widthTypes = ['default', 'small', 'large'];

export default defineComponent({
    name: 'CnSearch',
    emits: ['onSearch', 'onClear'],
    props: {
        outlineColour: {
            type: String,
            default: 'secondary',
        },
        placeholderText: {
            type: String,
            default: 'Placeholder text',
        },
        labelText: {
            type: String,
        },
        width: {
            type: String,
            required: false,
            default: 'default',
            validator: (prop: string) => widthTypes.includes(prop),
        },
        showSearchButton: {
            type: Boolean,
            default: true,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props: { width: string }, context) {
        const searchText = ref('');
        const defaultWidth = computed(() => props.width === 'default');
        const smallWidth = computed(() => props.width === 'small');
        const largeWidth = computed(() => props.width === 'large');

        const widths = computed(() => {
            return {
                // classes for when the text field is standard width
                'TextFieldWidth-default': defaultWidth.value,
                // classes for when the text field is a smaller width
                'TextFieldWidth-small': smallWidth.value,
                // classes for when the text field is a larger width
                'TextFieldWidth-large': largeWidth.value,
            };
        });

        const search = () => {
            context.emit('onSearch', searchText.value);
        };

        const clear = () => {
            context.emit('onClear');
        };

        const rules = reactive({
            required: computed((value) => !!value || 'Required.'),
        });

        return {
            searchText,
            widths,
            search,
            clear,
            rules,
        };
    },
    components: {
        VTextField,
        CnButton,
    },
});
