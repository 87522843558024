import {
    AccountInfo,
    Configuration,
    LogLevel,
    PublicClientApplication,
} from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        // auth config are in .env files
        clientId: process.env.VUE_APP_MSAL_AUTH_CLIENTID,
        authority: process.env.VUE_APP_MSAL_AUTH_AUTHORITY,
        knownAuthorities: [process.env.VUE_APP_MSAL_AUTH_DOMAIN],
        redirectUri: `${window.origin}${process.env.VUE_APP_REDIRECT_URI}`,
        postLogoutRedirectUri: `${window.origin}${process.env.VUE_APP_REDIRECT_URI}`,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (
                level: LogLevel,
                message: string,
                containsPii: boolean,
            ) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
            logLevel: LogLevel.Verbose,
        },
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: [process.env.VUE_APP_MSAL_SCOPE],
};

export const msalInstance = new PublicClientApplication(msalConfig);

type AccountIdentifiers = Partial<
    Pick<AccountInfo, 'homeAccountId' | 'localAccountId' | 'username'>
>;

export function accountIsEqual(
    arrayA: AccountIdentifiers[],
    arrayB: AccountIdentifiers[],
): boolean {
    if (arrayA == null || arrayB == null) {
        return false;
    }
    if (arrayA.length !== arrayB.length) {
        return false;
    }

    const comparisonArray = [...arrayB];

    return arrayA.every((elementA) => {
        const elementB = comparisonArray.shift();
        if (!elementA || !elementB) {
            return false;
        }

        return (
            elementA.homeAccountId === elementB.homeAccountId &&
            elementA.localAccountId === elementB.localAccountId &&
            elementA.username === elementB.username
        );
    });
}
