































































import {
    defineComponent,
    PropType,
    reactive,
    toRefs,
} from '@vue/composition-api';
import {
    VDialog,
    VCard,
    VToolbar,
    VSpacer,
    VBtn,
    VIcon,
    VContainer,
    VCardActions,
} from 'vuetify/lib/components';
import CnButton from '@/components/Cn-Button.vue';
import ResultsStep from './ResultsStep.vue';
import { TrainingPlanVm } from '@/models/TrainingPlanVm';
import { ITrainingPlanUnitStandard } from '@/api';
export default defineComponent({
    name: 'ConfirmTrainingPlanResultsDialog',
    components: {
        VDialog,
        VCard,
        VToolbar,
        VSpacer,
        VBtn,
        VIcon,
        VContainer,
        VCardActions,
        CnButton,
        ResultsStep,
    },
    emits: ['close', 'confirm'],
    props: {
        isOpen: {
            type: Boolean,
            required: true,
        },
        selectedTrainee: {
            type: Object as PropType<TrainingPlanVm | null>,
            required: true,
        },
        selectedTrainingPlan: {
            type: Object as PropType<TrainingPlanVm | null>,
            required: true,
        },
        unitStandardList: {
            type: Array as PropType<ITrainingPlanUnitStandard[]>,
            required: true,
        },
        existingUnitStandardList: {
            type: Array as PropType<ITrainingPlanUnitStandard[]>,
            required: true,
        },
        isSubmitted: {
            type: Boolean,
            required: true,
        },
        apiError: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: true,
        },
    },
    setup(props, context) {
        // #region hooks
        // #endregion

        // #region data
        const state = reactive({
            // TODO add state here
        });
        // #endregion

        // #region methods
        const handleOnClose = (e: MouseEvent) => {
            context.emit('close', e);
        };

        const handleConfirm = (e: MouseEvent) => {
            context.emit('confirm', e);
        };
        // #endregion

        // #region lifeCycle
        // #endregion

        return {
            ...toRefs(state),
            handleOnClose,
            handleConfirm,
        };
    },
});
