const getterNames = Object.freeze({
    // TODO add getter names
} as const);

const mutationNames = Object.freeze({
    setResults: 'SET_RESULTS',
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
} as const);

const actionNames = Object.freeze({
    searchForResults: 'SEARCH_FOR_RESULTS',
} as const);

const namespace = 'resultsEnteredState';

export { getterNames, mutationNames, actionNames, namespace };
