import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import { ApiException, UnitStandardClientInstance } from '@/api';
import { toUnitStandardVm, IUnitStandardVm } from '@/models/UnitStandardVm';

const state = {
    // TODO add State
    selectedUnitStandard: null as IUnitStandardVm | null,
    searchedUnitStandard: null as IUnitStandardVm | null,
    unitStandardCodeToSearch: '',
    loading: false,
    error: null as ApiException | null,
};

export type UnitStandardState = typeof state;

const getters: GetterTree<UnitStandardState, RootState> = {
    // TODO add getters
    [getterNames.loading]: (state) => state.loading,
    [getterNames.getSelectedUnitStandard]: (state) =>
        state.selectedUnitStandard,
    [getterNames.getSearchedUnitStandard]: (state) =>
        state.searchedUnitStandard,
};

const mutations: MutationTree<UnitStandardState> = {
    // TODO add mutations
    [mutationNames.setSelectedUnitStandard](
        state,
        unitStandard: IUnitStandardVm,
    ) {
        state.selectedUnitStandard = unitStandard;
    },
    [mutationNames.setSearchedUnitStandard](
        state,
        unitStandard: IUnitStandardVm,
    ) {
        state.searchedUnitStandard = unitStandard;
    },
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setUnitStandardCodeToSearch](
        state,
        unitStandardCodeToSearch: string,
    ) {
        state.unitStandardCodeToSearch = unitStandardCodeToSearch;
    },
};

const actions: ActionTree<UnitStandardState, RootState> = {
    // TODO add actions
    async [actionNames.fetchUnitStandard](context, code: number) {
        try {
            context.commit(mutationNames.setLoading, true);
            context.commit(mutationNames.setUnitStandardCodeToSearch, code);
            context.commit(mutationNames.setSearchedUnitStandard, null);
            const unitStandard = await UnitStandardClientInstance.get(code);
            if (unitStandard && unitStandard.result) {
                const unitStandardVm = toUnitStandardVm(unitStandard.result);
                context.commit(
                    mutationNames.setSearchedUnitStandard,
                    unitStandardVm,
                );
            }
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<UnitStandardState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
