
























import {
    defineComponent,
    computed,
    reactive,
    PropType,
} from '@vue/composition-api';
import { VTextField } from 'vuetify/lib/components';

const widthTypes = ['default', 'small', 'large'];

export default defineComponent({
    name: 'CnTextField',
    props: {
        value: {
            type: String,
            required: false,
            default: '',
        },
        outlineColour: {
            type: String,
            default: 'secondary',
        },
        backgroundColour: {
            type: String,
            default: 'white',
        },
        placeholderText: {
            type: String,
            default: 'Placeholder text',
        },
        appendIcon: {
            type: String,
            default: '',
        },
        width: {
            type: String,
            required: false,
            default: 'default',
            validator: (prop: string) => widthTypes.includes(prop),
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Array as PropType<Array<(value: string) => boolean | string>>,
            default: () => [],
        },
    },
    setup(props) {
        // #region hooks
        // #endregion

        // #region data
        const state = reactive({
            defaultWidth: computed(() => props.width === 'default'),
            smallWidth: computed(() => props.width === 'small'),
            largeWidth: computed(() => props.width === 'large'),
        });

        const widths = computed(() => {
            return {
                // classes for when the text field is standard width
                'TextFieldWidth-default':
                    state.defaultWidth && !props.fullWidth,
                // classes for when the text field is a smaller width
                'TextFieldWidth-small': state.smallWidth && !props.fullWidth,
                // classes for when the text field is a larger width
                'TextFieldWidth-large': state.largeWidth && !props.fullWidth,
            };
        });
        // #endregion

        // #region methods
        // #endregion

        // #region lifeCycle
        // #endregion

        return {
            widths,
        };
    },
    components: {
        VTextField,
    },
});
