const getterNames = Object.freeze({
    getTraineeTrainingPlans: 'GET_TRAINEE_TRAINING_PLANS',
    getSearchedTrainingPlans: 'GET_SEARCHED_TRAINING_PLANS',
} as const);

const mutationNames = Object.freeze({
    setMyPlans: 'SET_MY_PLANS',
    setSearchedPlans: 'SET_SEARCHED_PLANS',
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
    setTrainee: 'SET_TRAINEE',
    setTraineeTrainingPlan: 'SET_TRAINEE_TRAINING_PLAN',
} as const);

const actionNames = Object.freeze({
    fetchAllMyTrainingPlans: 'FETCH_MY_TRAINING_PLANS',
    searchTrainingPlans: 'SEARCH_TRAINING_PLANS',
   // searchTrainingPlansByTraineeId: 'GET_BY_TRAINEE_ID',
} as const);

const namespace = 'myTrainingPlansState';

export { getterNames, mutationNames, actionNames, namespace };
