import { PluginObject } from 'vue';
import { reactive } from '@vue/composition-api';
import {
    EventMessage,
    EventMessageUtils,
    EventType,
    InteractionStatus,
    PublicClientApplication,
    AccountInfo,
} from '@azure/msal-browser';
import { isEqualWith } from 'lodash-es';
import { accountIsEqual } from '@/authConfig';

export type MsalState = {
    instance: PublicClientApplication;
    inProgress: InteractionStatus;
    accounts: AccountInfo[];
};

const MSALplugin: PluginObject<PublicClientApplication> = {
    install: (vue, msalInstance) => {
        const inProgress = InteractionStatus.Startup;
        const accounts = msalInstance?.getAllAccounts();

        const state = reactive({
            instance: msalInstance,
            inProgress,
            accounts,
        });

        Object.defineProperty(vue.prototype, '$msal', {
            get() {
                return state;
            },
        });

        msalInstance?.addEventCallback((message: EventMessage) => {
            switch (message.eventType) {
                case EventType.ACCOUNT_ADDED:
                case EventType.ACCOUNT_REMOVED:
                case EventType.LOGIN_SUCCESS:
                case EventType.SSO_SILENT_SUCCESS:
                case EventType.HANDLE_REDIRECT_END:
                case EventType.LOGIN_FAILURE:
                case EventType.SSO_SILENT_FAILURE:
                case EventType.LOGOUT_END:
                case EventType.ACQUIRE_TOKEN_SUCCESS:
                case EventType.ACQUIRE_TOKEN_FAILURE: {
                    const currentAccounts = msalInstance.getAllAccounts();
                    if (
                        !isEqualWith(
                            currentAccounts,
                            state.accounts,
                            accountIsEqual,
                        )
                    ) {
                        state.accounts = currentAccounts;
                    }
                    break;
                }
            }

            const status = EventMessageUtils.getInteractionStatusFromEvent(
                message,
                state.inProgress,
            );

            if (status !== null) {
                state.inProgress = status;
            }
        });
    },
};

export default MSALplugin;
