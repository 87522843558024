const getterNames = Object.freeze({
} as const);

const mutationNames = Object.freeze({
    setUnitStandard: 'SET_UNIT_STANDARD',
    setLoading: 'SET_LOADING',
    setUnitStandardList: 'SET_UNIT_STANDARD_LIST',
    setError: 'SET_ERROR',
    setEnteredUnitStandardList: 'SET_ENTERED_UNIT_STANDARD_LIST',
} as const);

const actionNames = Object.freeze({
    fetchTrainingPlanUnitStandard: 'FETCH_TRAINING_PLAN_UNIT_STANDARD',
} as const);

const namespace = 'enterResultsState';

export { getterNames, mutationNames, actionNames, namespace };
