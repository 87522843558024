
















import { defineComponent, computed } from '@vue/composition-api';
import { VBtn } from 'vuetify/lib/components';
const buttonTypes = ['primary', 'secondary'];
export default defineComponent({
    name: 'CnButton',
    props: {
        type: {
            type: String,
            required: false,
            default: 'primary',
            validator: (prop: string) => buttonTypes.includes(prop),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        to: {
            type: String,
        },
    },
    components: {
        VBtn,
    },
    setup(props, context) {
        const isPrimary = computed(() => props.type === 'primary');
        const classes = computed(() => {
            return {
                // classes for when the button is primary
                'white--text': isPrimary.value,
                // classes for when the button is secondary
                'cn-dk-grey--text': !isPrimary.value,
            };
        });
        const buttonProps = computed(() => {
            return {
                depressed: isPrimary.value,
                // classes for when the button is secondary
                outlined: !isPrimary.value,
                color: props.type,
            };
        });
        return {
            isPrimary,
            classes,
            buttonProps,
        };
    },
});
