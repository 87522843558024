import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import { ApiException, ScopeClientInstance } from '@/api';
import { IScopeVm, toScopeVm } from '@/models/ScopeVm';

const state = {
    currentScopes: [] as IScopeVm[],
    loading: false,
    error: null as ApiException | null,
};

export type MyScopeState = typeof state;

const getters: GetterTree<MyScopeState, RootState> = {
    // TODO add getters
};

const mutations: MutationTree<MyScopeState> = {
    [mutationNames.setCurrentScope](state, scopes: IScopeVm[]) {
        state.currentScopes = scopes;
    },
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
};

const actions: ActionTree<MyScopeState, RootState> = {
    async [actionNames.searchForUnitStandard](context, payload?: string) {
        try {
            context.commit(mutationNames.setLoading, true);
            const { scopes } = await ScopeClientInstance.get(payload);
            if (scopes) {
                const scopeVms = scopes.map(toScopeVm);
                context.commit(mutationNames.setCurrentScope, scopeVms);
            }
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<MyScopeState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
