import { IUnitStandard } from '@/api';

export interface IUnitStandardVm {
    unitStandardId: string;
    unitStandardNo: string;
    description: string;
    credits?: number;
}

export function toUnitStandardVm(unitStandard: IUnitStandard): IUnitStandardVm {
    const vm: IUnitStandardVm = {
        unitStandardId: unitStandard.unitStandardId || '',
        unitStandardNo: unitStandard.frameworkElementCode || '',
        description: unitStandard.unitStandardName || '',
        credits: unitStandard.credits,
    };
    return vm;
}
