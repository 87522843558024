const getterNames = Object.freeze({
    // TODO add getter names
} as const);

const mutationNames = Object.freeze({
    setCurrentScope: 'SET_CURRENT_SCOPE',
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
} as const);

const actionNames = Object.freeze({
    searchForUnitStandard: 'SEARCH_FOR_UNIT_STANDARD',
} as const);

const namespace = 'myScopeState';

export { getterNames, mutationNames, actionNames, namespace };
