import { ScopeDto } from '@/api';

export interface IScopeVm {
    name?: string;
    unitStandards: IUnitStandardVM[];
}

export interface IUnitStandardVM {
    domain: string;
    unitStandard: string;
    description: string;
    credits: number;
    current: number;
    versions: string;
}

export function toScopeVm(scope: ScopeDto): IScopeVm {
    return {
        name: scope.name,
        unitStandards:
            scope.parentFrameworkElements?.flatMap(
                (pfe) =>
                    pfe.unitStandards?.map(
                        (us) =>
                            ({
                                domain: pfe.name,
                                unitStandard: us.frameworkElementCode,
                                description: us.unitStandardName,
                                credits: us.credits,
                                current: us.currentVersion,
                                versions: us.versions,
                            } as IUnitStandardVM),
                    ) || [],
            ) || [],
    };
}
