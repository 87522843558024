import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
//import { toResultCreatedResponseVm, IResultCreatedReponseVm } from '@/models/ResultCreatedVm';
import {
    resultCreationResponseToTrainingPlanUnitStandardVm,
    ITrainingPlanUnitStandardVm,
} from '@/models/TrainingPlanUnitStandardVm';
import {
    ApiException,
    CreateResultRequestDto,
    ResultClientInstance,
} from '@/api';
import { result } from 'lodash-es';

const state = {
    traineeId: '',
    unitStandardId: '',
    dateAchieved: '',
    isRcc: false,
    casualCredit: false,
    poNumber: '',
    debtorCode: '',
    createdResultResponses: [] as ITrainingPlanUnitStandardVm[],
    loading: false,
    error: '',
};

export type CreateResultState = typeof state;

const getters: GetterTree<CreateResultState, RootState> = {
    // TODO add getters
    [getterNames.getLoading]: (state) => state.loading,
    [getterNames.getError]: (state) => state.error,
    [getterNames.getResultCreatedResponses]: (state) => state.createdResultResponses
};

const mutations: MutationTree<CreateResultState> = {
    [mutationNames.setTraineeId](state, traineeId: string) {
        state.traineeId = traineeId;
    },
    [mutationNames.setUnitStandardId](state, unitStandardId: string) {
        state.unitStandardId = unitStandardId;
    },
    [mutationNames.setDateAchieved](state, dateAchieved: string) {
        state.dateAchieved = dateAchieved;
    },
    [mutationNames.setIsRcc](state, isRcc: boolean) {
        state.isRcc = isRcc;
    },
    [mutationNames.setCasualCredit](state, casualCredit: boolean) {
        state.casualCredit = casualCredit;
    },
    [mutationNames.setPoNumber](state, poNumber: string) {
        state.poNumber = poNumber;
    },
    [mutationNames.setDebtorCode](state, debtorCode: string) {
        state.debtorCode = debtorCode;
    },
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setError](state, error: string) {
        state.error = error;
    },
    [mutationNames.setApiReponse](state, resultResponses: ITrainingPlanUnitStandardVm[]) {
        state.createdResultResponses = resultResponses;
    },
};

const actions: ActionTree<CreateResultState, RootState> = {
    // TODO add actions
    async [actionNames.postCasualCreditToApi](context, payload) {
        try {
            context.commit(mutationNames.setLoading, true);
            const result = await ResultClientInstance.post2(
                new CreateResultRequestDto({
                    isRcc: context.state.casualCredit
                        ? undefined
                        : context.state.isRcc,
                    traineeId:
                        context.rootState.myTrainingPlansState?.trainee
                            ?.contactId,
                    unitStandardId:
                        context.rootState.unitStandardState
                            ?.selectedUnitStandard?.unitStandardId,
                    unitStandardName:
                            context.rootState.unitStandardState
                                ?.selectedUnitStandard?.description,
                    unitStandardCode:
                                context.rootState.unitStandardState
                                    ?.selectedUnitStandard?.unitStandardNo,
                    dateAchieved: context.state.dateAchieved,
                    casualCredit: context.state.casualCredit,
                    poNumber: context.state.poNumber,
                    debtorCode: context.state.debtorCode,
                }),
            );

        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
    async [actionNames.postTrainingPlanToApi](context, payload) {
        try {
            context.commit(mutationNames.setError, '');

            context.commit(mutationNames.setLoading, true);
            const apiResult = await ResultClientInstance.post(
                context.rootState.enterResultsState?.enteredUnitStandardList.map(
                    (us) =>
                        new CreateResultRequestDto({
                            isRcc: us.isRcc,
                            traineeId:
                                context.rootState.myTrainingPlansState?.trainee
                                    ?.contactId,
                            unitStandardId: us.unitStandardId,
                            unitStandardName: us.unitStandardName,
                            unitStandardCode: us.unitStandardCode,
                            dateAchieved: us.dateAchieved,
                            casualCredit: context.state.casualCredit,
                        }),
                ) ?? [],
            );

            const unitStandardWithResult = apiResult.result?.map(resultCreationResponseToTrainingPlanUnitStandardVm);

            context.commit(mutationNames.setApiReponse, unitStandardWithResult);
            if (apiResult.result) {
                // If any item in the response is error (crmSaveStatus = 0), consider the transaction is error.
                const failure = apiResult.result.find(
                    (r) => r.crmSaveStatus == 0,
                );

                if(failure) {
                    context.commit(mutationNames.setError, apiResult.message);
                }
            }
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<CreateResultState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
