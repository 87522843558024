import Vue from 'vue';
import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import { ApiException, TrainingPlanUnitStandardClientInstance } from '@/api';
import {
    ITrainingPlanUnitStandardVm,
    toTrainingPlanUnitStandardVm,
} from '@/models/TrainingPlanUnitStandardVm';

const state = {
    loading: false,
    unitStandardList: [] as ITrainingPlanUnitStandardVm[],
    enteredUnitStandardList: [] as ITrainingPlanUnitStandardVm[],
    existingUnitStandardList: [] as ITrainingPlanUnitStandardVm[],
    error: null as ApiException | null,
};

export type EnterResultsState = typeof state;

const getters: GetterTree<EnterResultsState, RootState> = {
    // TODO add getters
};

const mutations: MutationTree<EnterResultsState> = {
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setUnitStandardList](
        state,
        items: ITrainingPlanUnitStandardVm[],
    ) {
        state.unitStandardList = items;
        //Get new entered results
        state.enteredUnitStandardList =
            state.unitStandardList.filter(
                (a) => !a.isExistingResult && a.dateAchieved != undefined && a.dateAchieved != ""
            ) || new Array<ITrainingPlanUnitStandardVm>();

        //Get existing results, previously entered
        state.existingUnitStandardList =
            state.unitStandardList.filter(
                (a) => a.isExistingResult
            ) || new Array<ITrainingPlanUnitStandardVm>();
    },
    [mutationNames.setEnteredUnitStandardList](state,
        items: ITrainingPlanUnitStandardVm[],
    ) {
        state.enteredUnitStandardList =
            items.filter(
                (a) => !a.isExistingResult && a.dateAchieved != undefined && a.dateAchieved != ""
            ) || new Array<ITrainingPlanUnitStandardVm>();
    },
    [mutationNames.setUnitStandard](
        state,
        {
            unitStandard,
            index,
        }: { unitStandard: ITrainingPlanUnitStandardVm; index: number },
    ) {
        Vue.set(state.unitStandardList, index, unitStandard);
    },
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
};

const actions: ActionTree<EnterResultsState, RootState> = {
    async [actionNames.fetchTrainingPlanUnitStandard](
        context,
        trainingPlanId: string,
    ) {
        try {
            context.commit(mutationNames.setLoading, true);
            const items = await TrainingPlanUnitStandardClientInstance.get(
                trainingPlanId,
            );
            const unitStandards =
                items.length > 0
                    ? items[0].trainingPlanUnitStandards?.map(
                          toTrainingPlanUnitStandardVm,
                      )
                    : [];
            context.commit(mutationNames.setUnitStandardList, unitStandards);
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<EnterResultsState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
