











import { defineComponent } from '@vue/composition-api';
import { VDatePicker } from 'vuetify/lib/components';
export default defineComponent({
    name: 'CnDatePicker',
    props: {
        datePickerColour: {
            type: String,
            default: 'primary',
        },
        showAdjacentMonths: {
            type: Boolean,
            default: true,
        },
        value: {
            type: String,
            default: () =>
                new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substring(0, 10),
        },
        isDob: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getMaxDate() {
            const today = new Date(
                Date.now() - new Date().getTimezoneOffset() * 60000,
            );
            if (this.$props.isDob) {
                const maxDate = today;
                maxDate.setFullYear(maxDate.getFullYear() - 10);
                return maxDate.toISOString().substring(0, 10);
            }
            return today.toISOString().substring(0, 10);
        },
    },
    components: {
        VDatePicker,
    },
    setup() {
        return {
            // TODO return stuff to template
        };
    },
});
