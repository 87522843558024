const getterNames = Object.freeze({
    getLoading: 'GET_LOADING',
    getError: 'GET_ERROR',
    getResultCreatedResponses: 'GET_RESULT_CREATED_RESPONSE'
} as const);

const mutationNames = Object.freeze({
    setTraineeId: 'SET_TRAINEE_ID',
    setUnitStandardId: 'SET_UNIT_STANDARD_ID',
    setDateAchieved: 'SET_DATE_ACHIEVED',
    setIsRcc: 'SET_IS_RCC',
    setCasualCredit: 'SET_CASUAL_CREDIT',
    setPoNumber: 'SET_PO_NUMBER',
    setDebtorCode: 'SET_DEBTOR_CODE',
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
    setApiReponse: 'SET_API_RESPONSE'
} as const);

const actionNames = Object.freeze({
    postCasualCreditToApi: 'POST_CASUAL_CREDIT_TO_API',
    postTrainingPlanToApi: 'POST_TRAINING_PLAN_TO_API',
} as const);

const namespace = 'createResultState';

export { getterNames, mutationNames, actionNames, namespace };
