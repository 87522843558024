import { ITrainingPlanUnitStandard } from '@/api';
import { format, parse } from 'date-fns';
import { CreateResultResponseDto } from '@/api';

export interface ITrainingPlanUnitStandardVm {
    unitStandardId: string;
    unitStandardCode: string;
    unitStandardName: string;
    dateAchieved?: string | undefined;
    isRcc: boolean;
    isExistingResult: boolean;
    saveStatus: string;
    saveErrorMessage: string;
}

export function toTrainingPlanUnitStandardVm(
    trainingPlanUnitStandard: ITrainingPlanUnitStandard,
) {
    const parsedDate = trainingPlanUnitStandard.dateAchieved
        ? new Date(trainingPlanUnitStandard.dateAchieved)
        : undefined;
    const vm: ITrainingPlanUnitStandardVm = {
        unitStandardId: trainingPlanUnitStandard.unitStandardId || '',
        unitStandardCode: trainingPlanUnitStandard.unitStandardCode || '',
        unitStandardName: trainingPlanUnitStandard.unitStandardName || '',
        dateAchieved: parsedDate ? format(parsedDate, 'yyyy-MM-dd') : '',
        isRcc: trainingPlanUnitStandard.isRcc || false,
        isExistingResult: trainingPlanUnitStandard.isExistingResult || false,
        saveStatus: '',
        saveErrorMessage: '',
    };
    return vm;
}

export function resultCreationResponseToTrainingPlanUnitStandardVm(
    createResultResponse: CreateResultResponseDto,
) {

    const parsedDate = createResultResponse.dateAchieved
        ? new Date(createResultResponse.dateAchieved)
        : undefined;
    const vm: ITrainingPlanUnitStandardVm = {
        unitStandardId: createResultResponse.unitStandardId || '',
        unitStandardCode: createResultResponse.unitStandardCode || '',
        unitStandardName: createResultResponse.unitStandardName || '',
        dateAchieved: parsedDate ? format(parsedDate, 'yyyy-MM-dd') : '',
        isRcc: createResultResponse.isRcc || false,
        isExistingResult: createResultResponse.isExistingResult || false,
        saveStatus:
            createResultResponse.crmSaveStatus == 1
                ? 'Successfully saved'
                : 'Failed to save',
        saveErrorMessage: createResultResponse.crmSaveErrorMessage || ''
    };
    return vm;
}
