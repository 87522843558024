import type { RootState } from '@/store';
import type { TraineeState } from '@/store/modules/trainee/Trainee';

const getterNames = Object.freeze({
    getNewTrainee: 'GET_NEW_TRAINEE',
    getLoading: 'GET_LOADING',
    getError: 'GET_ERROR',
} as const);

const mutationNames = Object.freeze({
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
    setTrainee: 'SET_TRAINEE',
} as const);

const actionNames = Object.freeze({
    createNewTrainee: 'CREATE_NEW_TRAINEE',
} as const);

const namespace = 'traineeState';

export { getterNames, mutationNames, actionNames, namespace };
