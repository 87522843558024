







































































import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    toRefs,
} from '@vue/composition-api';
import {
    VApp,
    VAppBar,
    VContainer,
    VImg,
    VBtn,
    VSpacer,
    VFooter,
} from 'vuetify/lib/components';
import {
    AuthenticationResult,
    EventType,
    InteractionType,
} from '@azure/msal-browser';
import { useMsal } from '@/hooks/useMsal';
import { loginRequest } from './authConfig';
import { CustomNavigationClient } from './router/NavigationClient';
import { useIsAuthenticated } from './hooks/useIsAuthenticated';
import { useMsalAuthentication } from './hooks/useMsalAuthentication';
import { useAxiosTokenInterceptor } from './api';
import { GetterNames } from '@/store/types';

export default defineComponent({
    name: 'App',
    components: {
        VApp,
        VAppBar,
        VContainer,
        VImg,
        VBtn,
        VSpacer,
        VFooter,
    },
    setup(props, context) {
        // #region hooks
        const { msalInstance, accounts } = useMsal(context);
        const { acquireToken, accessToken } = useMsalAuthentication(
            context,
            InteractionType.Redirect,
            loginRequest,
        );
        const isAuthenticated = useIsAuthenticated(context);
        useAxiosTokenInterceptor(accessToken);
        // #endregion

        // #region data
        // placing refs and computed inside reactive makes them accessable without using .value
        const state = reactive({
            // blank computed makes these readonly
            navHeight: computed(() => 80),
            footerHeight: computed(() => 100),
            isAuthenticated,
            name: computed<string>(
                () => context.root.$store.getters[GetterNames.name],
            ),
        });
        // #endregion

        //#region methods
        const logout = () => {
            msalInstance?.logoutRedirect();
        };
        const login = () => {
            msalInstance?.loginRedirect();
        };
        //#endregion

        // #region lifeCycle
        onMounted(async () => {
            // const navigationClient = new CustomNavigationClient(
            //     context.root.$router,
            // );
            // msalInstance?.setNavigationClient(navigationClient);

            if (accounts.value.length > 0) {
                msalInstance?.setActiveAccount(accounts.value[0]);
            }
            msalInstance?.addEventCallback((event) => {
                if (
                    event.eventType === EventType.LOGIN_SUCCESS &&
                    event.payload
                ) {
                    const payload = event.payload as AuthenticationResult;
                    const account = payload.account;
                    msalInstance.setActiveAccount(account);
                    acquireToken();
                }
            });

            if (isAuthenticated.value) {
                await acquireToken();
            }
        });
        // #endregion

        return {
            ...toRefs(state),
            logout,
            login,
        };
    },
});
