export * from './connexis';

import { Ref } from '@vue/composition-api';
import axios from 'axios';
import {
    TrainingPlanClient,
    ScopeClient,
    UnitStandardClient,
    UnitStandardVersionClient,
    ResultClient,
    TrainingPlanUnitStandardClient,
    TraineeClient,
    AccountClient,
} from './connexis';

const axiosInstance = axios.create({
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: 'json',
});

const TrainingPlanClientInstance = new TrainingPlanClient(
    process.env.VUE_APP_API_BASE_URI,
    axiosInstance,
);

const ScopeClientInstance = new ScopeClient(
    process.env.VUE_APP_API_BASE_URI,
    axiosInstance,
);

const UnitStandardClientInstance = new UnitStandardClient(
    process.env.VUE_APP_API_BASE_URI,
    axiosInstance,
);

const UnitStandardVersionClientInstance = new UnitStandardVersionClient(
    process.env.VUE_APP_API_BASE_URI,
    axiosInstance,
);

const ResultClientInstance = new ResultClient(
    process.env.VUE_APP_API_BASE_URI,
    axiosInstance,
);
const TrainingPlanUnitStandardClientInstance =
    new TrainingPlanUnitStandardClient(
        process.env.VUE_APP_API_BASE_URI,
        axiosInstance,
    );

const TraineeClientinstance = new TraineeClient(
    process.env.VUE_APP_API_BASE_URI,
    axiosInstance,
);
const AccountClientInstance = new AccountClient(
    process.env.VUE_APP_API_BASE_URI,
    axiosInstance,
);

const useAxiosTokenInterceptor = (accessToken: Ref<string>): void => {
    axiosInstance.interceptors.request.use(
        (config) => {
            if (accessToken.value) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                config.headers![
                    'Authorization'
                ] = `Bearer ${accessToken.value}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        },
    );
};

export {
    axiosInstance,
    useAxiosTokenInterceptor,
    TrainingPlanClientInstance,
    ScopeClientInstance,
    UnitStandardClientInstance,
    UnitStandardVersionClientInstance,
    ResultClientInstance,
    TrainingPlanUnitStandardClientInstance,
    AccountClientInstance,
    TraineeClientinstance,
};
