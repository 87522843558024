
















import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api';
import { VSelect } from 'vuetify/lib/components';
const widthTypes = ['default', 'small', 'large'];
export default defineComponent({
    name: 'CnSelect',
    components: { VSelect },
    props: {
        items: {
            type: Array,
            required: true,
            default: () => [],
        },
        label: {
            type: String,
            required: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
        placeholderText: {
            type: String,
            default: 'Select',
        },
        width: {
            type: String,
            required: false,
            default: 'default',
            validator: (prop: string) => widthTypes.includes(prop),
        },
    },
    setup(props, context) {
        // #region hooks
        // #endregion

        // #region data
        const state = reactive({
            defaultWidth: computed(() => props.width === 'default'),
            smallWidth: computed(() => props.width === 'small'),
            largeWidth: computed(() => props.width === 'large'),
        });

        const widths = computed(() => {
            return {
                // classes for when the text field is standard width
                'TextFieldWidth-default':
                    state.defaultWidth && !props.fullWidth,
                // classes for when the text field is a smaller width
                'TextFieldWidth-small': state.smallWidth && !props.fullWidth,
                // classes for when the text field is a larger width
                'TextFieldWidth-large': state.largeWidth && !props.fullWidth,
            };
        });
        // #endregion

        // #region methods
        // #endregion

        // #region lifeCycle
        // #endregion

        return {
            ...toRefs(state),
            widths,
        };
    },
});
