




















































import {
    computed,
    defineComponent,
    PropType,
    reactive,
    toRefs,
} from '@vue/composition-api';
import { VContainer, VRow, VCol, VSwitch } from 'vuetify/lib';
import { ITrainingPlanUnitStandard } from '@/api';
import CnDateField from '../Cn-DateField.vue';
export default defineComponent({
    name: 'ResultRow',
    components: {
        VContainer,
        VRow,
        VCol,
        VSwitch,
        CnDateField,
    },
    props: {
        item: {
            type: Object as PropType<ITrainingPlanUnitStandard>,
            required: true,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['date-achieved', 'is-rcc'],
    setup(props, context) {
        // #region hooks
        // #endregion

        // #region data
        const state = reactive({
            dateAchieved: computed({
                get: () => props.item.dateAchieved,
                set: (value) => {
                    context.emit('date-achieved', value);
                },
            }),
            isRcc: computed({
                get: () => props.item.isRcc == true,
                set: (value) => {
                    context.emit('is-rcc', value);
                },
            }),
        });
        // #endregion

        // #region methods

        // #endregion

        // #region lifeCycle
        // #endregion

        return {
            ...toRefs(state),
        };
    },
});
