import { SetupContext } from '@vue/composition-api';
import { Commit, Dispatch, Store } from 'vuex';
import { RootState } from '@/store';

/**
 * Extracts the components of vuex in a more convenient setup for the composition api
 * @param context The context for the component this is being used from
 * @returns The components of vuex in a more convenient setup for composition api
 */
export function useVuexStore(context: SetupContext): {
    commit: Commit;
    dispatch: Dispatch;
    getters: Record<string, unknown>;
    state: Required<RootState>;
} {
    const { commit, dispatch, getters, state } = context.root
        .$store as Store<RootState>;

    return { commit, dispatch, getters, state: state as Required<RootState> };
}
