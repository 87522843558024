import {
    PopupRequest,
    RedirectRequest,
    SilentRequest,
    AuthenticationResult,
    AuthError,
    InteractionType,
    InteractionStatus,
} from '@azure/msal-browser';
import { computed, reactive, SetupContext, toRefs } from '@vue/composition-api';
import { GetterNames, MutationNames } from '@/store/types';
import { useMsal } from './useMsal';

// interface IMsalAuthenticationResult {
//     acquireToken: (
//         requestOverride?: PopupRequest | RedirectRequest | SilentRequest,
//     ) => Promise<void>;
//     result: Ref<AuthenticationResult | null>;
//     error: Ref<AuthError | null>;
//     inProgress: Ref<boolean>;
// }

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useMsalAuthentication(
    context: SetupContext,
    interactionType: InteractionType,
    request: PopupRequest | RedirectRequest | SilentRequest,
) {
    const { msalInstance, inProgress: msalInProgress } = useMsal(context);

    const state = reactive({
        inProgress: false,
        result: computed<AuthenticationResult | null>({
            get: () => context.root.$store.state.auth.result,
            set: (result) =>
                context.root.$store.commit(
                    MutationNames.setAuthenticationResult,
                    result,
                ),
        }),
        accessToken: computed<string>(
            () => context.root.$store.getters[GetterNames.accessToken],
        ),
        error: null as AuthError | null,
    });

    const acquireToken = async (
        requestOverride?: PopupRequest | RedirectRequest | SilentRequest,
    ) => {
        if (!state.inProgress) {
            state.inProgress = true;
            const tokenRequest = requestOverride || request;

            if (
                msalInProgress.value === InteractionStatus.Startup ||
                msalInProgress.value === InteractionStatus.HandleRedirect
            ) {
                try {
                    const response =
                        await msalInstance?.handleRedirectPromise();
                    if (response) {
                        state.result = response;
                        state.error = null;
                        return;
                    }
                } catch (e) {
                    state.result = null;
                    state.error = e as AuthError;
                    return;
                }
            }

            try {
                const response =
                    (await msalInstance?.acquireTokenSilent(tokenRequest)) ??
                    null;
                state.result = response;
                state.error = null;
            } catch (e) {
                if (msalInProgress.value !== InteractionStatus.None) {
                    return;
                }

                if (interactionType === InteractionType.Popup) {
                    msalInstance
                        ?.loginPopup(tokenRequest)
                        .then((response) => {
                            state.result = response;
                            state.error = null;
                        })
                        .catch((e) => {
                            state.error = e;
                            state.result = null;
                        });
                } else if (interactionType === InteractionType.Redirect) {
                    await msalInstance
                        ?.loginRedirect(tokenRequest)
                        .catch((e) => {
                            state.error = e;
                            state.result = null;
                        });
                }
            }
            state.inProgress = false;
        }
    };

    return {
        acquireToken,
        ...toRefs(state),
    };
}
export { useMsalAuthentication };
