import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import { ApiException, TrainingPlanClientInstance } from '@/api';
import { toTrainingPlanVm, TrainingPlanVm } from '@/models/TrainingPlanVm';

const state = {
    myPlans: [] as TrainingPlanVm[],
    searchedPlans: [] as TrainingPlanVm[],
    trainee: null as TrainingPlanVm | null,
    traineeTrainingPlan: null as TrainingPlanVm | null,
    loading: false,
    error: null as ApiException | null,
};

export type MyTrainingPlansState = typeof state;

const getters: GetterTree<MyTrainingPlansState, RootState> = {
    [getterNames.getTraineeTrainingPlans]: (state) =>
        state.searchedPlans?.filter(
            (plan) => plan.contactId === state.trainee?.contactId,
        ),
    [getterNames.getSearchedTrainingPlans]: (state) => state.searchedPlans,
};

const mutations: MutationTree<MyTrainingPlansState> = {
    [mutationNames.setMyPlans](state, plans: TrainingPlanVm[]) {
        state.myPlans = plans;
    },
    [mutationNames.setSearchedPlans](state, plans: TrainingPlanVm[]) {
        state.searchedPlans = plans;
    },
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
    [mutationNames.setTrainee](state, trainee: TrainingPlanVm) {
        state.trainee = trainee;
    },
    [mutationNames.setTraineeTrainingPlan](
        state,
        traineeTrainingPlan: TrainingPlanVm,
    ) {
        state.traineeTrainingPlan = traineeTrainingPlan;
    },
};
interface TrainingPlanSearchParam {
    searchText: string
}

const actions: ActionTree<MyTrainingPlansState, RootState> = {
    async [actionNames.fetchAllMyTrainingPlans](context) {
        try {
            context.commit(mutationNames.setLoading, true);
            const tPlans = await TrainingPlanClientInstance.myTrainingPlans();
            const tPlansVm = tPlans.map(toTrainingPlanVm);
            context.commit(mutationNames.setMyPlans, tPlansVm);
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
    async [actionNames.searchTrainingPlans](context, payload: TrainingPlanSearchParam) {
        try {
            context.commit(mutationNames.setLoading, true);
            const tPlans = await TrainingPlanClientInstance.searchTrainingPlans(
                payload.searchText,
            );
            const tPlansVm = tPlans.map(toTrainingPlanVm);
            context.commit(mutationNames.setSearchedPlans, tPlansVm);
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
    // async [actionNames.searchTrainingPlansByTraineeId](
    //     context,
    //     traineeId: string,
    // ) {
    //     try {
    //         context.commit(mutationNames.setLoading, true);
    //         const tPlans = await TrainingPlanClientInstance.get(
    //             null,
    //             null,
    //             null,
    //             traineeId,
    //             null,
    //         );
    //         const tPlansVm = tPlans.map(toTrainingPlanVm);
    //         context.commit(mutationNames.setSearchedPlans, tPlansVm);
    //     } catch (e) {
    //         context.commit(mutationNames.setError, e);
    //     } finally {
    //         context.commit(mutationNames.setLoading, false);
    //     }
    // },
};

const module: Module<MyTrainingPlansState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };

export default module;
