import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import { AccountClientInstance, ApiException, IAccountDto } from '@/api';

const state = {
    // TODO add State
    searchedAccount: null as IAccountDto | null,
    debtorNumberToSearch: '',
    loading: false,
    error: null as ApiException | null,
};

export type AccountState = typeof state;

const getters: GetterTree<AccountState, RootState> = {
    // TODO add getters
    [getterNames.loading]: (state) => state.loading,
    [getterNames.getSearchedAccount]: (state) => state.searchedAccount,
};

const mutations: MutationTree<AccountState> = {
    // TODO add mutations
    [mutationNames.setSearchedAccount](state, account: IAccountDto) {
        state.searchedAccount = account;
    },
    [mutationNames.setDebtorNumberToSearch](
        state,
        debtorNumberToSearch: string,
    ) {
        state.debtorNumberToSearch = debtorNumberToSearch;
    },
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
};

const actions: ActionTree<AccountState, RootState> = {
    // TODO add actions
    async [actionNames.fetchDebtorNumber](context, code: string) {
        try {
            context.commit(mutationNames.setLoading, true);
            context.commit(mutationNames.setDebtorNumberToSearch, code);
            context.commit(mutationNames.setSearchedAccount, null);
            const debtorNumber = await AccountClientInstance.get(code);
            if (debtorNumber && debtorNumber.result) {
                context.commit(mutationNames.setSearchedAccount, debtorNumber);
            }
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<AccountState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
