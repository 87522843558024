










































































































































































































































































































































































































import {
    computed,
    defineComponent,
    onMounted,
    reactive,
    ref,
    toRefs,
    watch,
    ComponentInstance,
    watchEffect,
} from '@vue/composition-api';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '@/authConfig';
import { useMsalAuthentication } from '@/hooks/useMsalAuthentication';
import {
    getterNames as TrainingPlansGetters,
    actionNames as TrainingPlansActions,
    namespace as TrainingPlansNamespace,
    mutationNames as TrainingPlansMutations,
} from '@/store/modules/myTrainingPlans/MyTrainingPlans';
import {
    getterNames as UnitStandardGetters,
    actionNames as UnitStandardActions,
    namespace as UnitStandardNamespace,
    mutationNames as UnitStandardMutations,
} from '@/store/modules/unitStandard/UnitStandard';
import {
    actionNames as EnterResultsActions,
    namespace as EnterResultsNamespace,
    mutationNames as EnterResultsMutation,
} from '@/store/modules/enterResults/EnterResults';
import {
    actionNames as UnitStandardVersionsActions,
    namespace as UnitStandardVersionsNamespace,
    mutationNames as UnitStandardVersionsMutations,
} from '@/store/modules/unitStandardVersions/UnitStandardVersions';
import {
    namespace as CreateResultNamespace,
    mutationNames as CreateResultMutations,
    actionNames as CreateResultActions,
    getterNames as CreateResultGetters,
} from '@/store/modules/createResult/CreateResult';
import {
    actionNames as AccountActions,
    namespace as AccountNamespace,
    mutationNames as AccountMutations,
} from '@/store/modules/account/Account';
import {
    actionNames as TraineeActions,
    namespace as TraineeNamespace,
    mutationNames as TraineeMutations,
    getterNames as TraineeGetters,
} from '@/store/modules/trainee/Trainee';
import {
    VLayout,
    VRow,
    VCol,
    VSpacer,
    VDivider,
    VStepper,
    VStepperStep,
    VStepperContent,
    VContainer,
    VBanner,
    VForm,
    VSelect,
    VCard,
} from 'vuetify/lib/components';
import CnDataTable from '@/components/Cn-DataTable.vue';
import CnButton from '@/components/Cn-Button.vue';
import CnTextField from '@/components/Cn-TextField.vue';
import { TrainingPlanVm } from '@/models/TrainingPlanVm';
import { IUnitStandardVm } from '@/models/UnitStandardVm';
import CnSearch from '@/components/Cn-Search.vue';
import CnDateField from '@/components/Cn-DateField.vue';
import ResultsStep from '@/components/EnterResults/ResultsStep.vue';
import {
    ApiException,
    Contact,
    IAccountDto,
    ITrainingPlanUnitStandard,
} from '@/api';
import ConfirmTrainingPlanResultsDialog from '@/components/EnterResults/ConfirmTrainingPlanResultsDialog.vue';
import ConfirmCasualCreditResultsDialog from '@/components/EnterResults/ConfirmCasualCreditResultsDialog.vue';
import { useVuexStore } from '@/hooks/useVuexStore';
import CreateNewTrainee from '@/components/EnterResults/CreateNewTrainee.vue';
import { debounce } from 'lodash-es';
import { ITrainingPlanUnitStandardVm } from '@/models/TrainingPlanUnitStandardVm';
import { format } from 'date-fns';

type VFormComponent = ComponentInstance & { validate: () => boolean };

export default defineComponent({
    name: 'EnterResults',
    components: {
        VLayout,
        VDivider,
        VRow,
        VCol,
        VSpacer,
        VStepper,
        VStepperStep,
        VStepperContent,
        CnSearch,
        VBanner,
        VForm,
        VSelect,
        VCard,
        CnDataTable,
        CnButton,
        VContainer,
        CnTextField,
        CnDateField,
        ResultsStep,
        ConfirmTrainingPlanResultsDialog,
        ConfirmCasualCreditResultsDialog,
        CreateNewTrainee,
    },
    setup(props, context) {
        //#region hooks
        const { accessToken, acquireToken } = useMsalAuthentication(
            context,
            InteractionType.Redirect,
            loginRequest,
        );
        const { state, commit, dispatch } = useVuexStore(context);

        //#endregion

        //#region data
        // ref for the results VForm
        const casualCreditForm = ref<VFormComponent | null>(null);
        const trainingPlanForm = ref<VFormComponent | null>(null);
        const currentStep = ref(1);

        // validation rules for unitStandard Search field
        const unitStandardSearchRules = {
            isNumber: (value: string | null) =>
                value?.match(/\d+/) != null ||
                value == null ||
                'Invalid Unit Standard code',
        };

        // validation rules for the PO Number field
        const poNumberRules = {
            required: (value: string | null) => {
                if (
                    step3State.searchDebtorNumber == null ||
                    step3State.searchDebtorNumber.length < 1
                ) {
                    return true;
                }
                if (value != null && value.length > 0) {
                    return true;
                }
                return 'Please enter PO Number';
            },
        };

        const debtorNumberRules = {
            required: () => {
                if (step3State.isValidDebtorNumber) {
                    return true;
                }
                return 'Invalid Debtor Code';
            },
        };

        const searchedTrainingPlans = computed<TrainingPlanVm[]>(
            () =>
                context.root.$store.state[TrainingPlansNamespace].searchedPlans,
        );

        const traineeTrainingPlans = computed<TrainingPlanVm[]>(() => {
            return context.root.$store.getters[
                `${TrainingPlansNamespace}/${TrainingPlansGetters.getTraineeTrainingPlans}`
            ];
        });

        // collective state for step 1
        const step1State = reactive({
            // computed with getter and setter that encapsulates the trainee and the mutation setTrainee
            selectedTrainee: computed<TrainingPlanVm | null>({
                get: () =>
                    context.root.$store.state[TrainingPlansNamespace].trainee,
                set: (value) => {
                    context.root.$store.commit(
                        `${TrainingPlansNamespace}/${TrainingPlansMutations.setTrainee}`,
                        value,
                    );
                },
            }),
            searchedTrainees: computed(() => {
                const uniqueTrainees = [
                    ...new Map(
                        searchedTrainingPlans.value.map(
                            (tp: TrainingPlanVm) => [tp.name + tp.nSN + tp.dateOfBirth, tp],
                        ),
                    ).values(),
                ];
                return uniqueTrainees;
            }),
            showSearchResult: false,
            showCreateNewTrainee: false,
            trainingPlansLoading: computed<boolean>(
                () => context.root.$store.state[TrainingPlansNamespace].loading,
            ),
            traineeCreationError: [] as string[],
            newTraineeCreationLoading: computed<boolean>(() => {
                return context.root.$store.getters[
                    `${TraineeNamespace}/${TraineeGetters.getLoading}`
                ];
            }),
        });
        // collective state for step 2
        const step2State = reactive({
            showCasualCredit: false,
            trainingPlansList: computed<TrainingPlanVm[] | null>(() => {
                return step1State.selectedTrainee != null
                    ? traineeTrainingPlans.value.filter((t) => t.trainingPlanID)
                    : searchedTrainingPlans.value.filter(
                          (t) => t.trainingPlanID,
                      );
            }),
            searchUnitStandardCode: null as string | null,
            unitStandardCodeToSearch: computed<string>(
                () => state.unitStandardState.unitStandardCodeToSearch,
            ),
            unitStandardLoading: computed<boolean>(
                () => context.root.$store.state[UnitStandardNamespace].loading,
            ),
            searchedUnitStandardResult: computed<IUnitStandardVm>(() => {
                return context.root.$store.getters[
                    `${UnitStandardNamespace}/${UnitStandardGetters.getSearchedUnitStandard}`
                ];
            }),
            // computed with getter and setter that encapsulates the traineeTrainingPlan and the mutation setTraineeTrainingPlan
            selectedTrainingPlan: computed<TrainingPlanVm | null>({
                get: () =>
                    context.root.$store.state[TrainingPlansNamespace]
                        .traineeTrainingPlan,
                set: (value) => {
                    context.root.$store.commit(
                        `${TrainingPlansNamespace}/${TrainingPlansMutations.setTraineeTrainingPlan}`,
                        value,
                    );
                },
            }),
        });
        // collective state for step 3
        const step3State = reactive({
            // computed with getter and setter that encapsulates the selectedUnitStandard and the mutation setSelectedUnitStandard
            selectedUnitStandard: computed<IUnitStandardVm | null>({
                get: () => state[UnitStandardNamespace].selectedUnitStandard,
                set: (value) => {
                    commit(
                        `${UnitStandardNamespace}/${UnitStandardMutations.setSelectedUnitStandard}`,
                        value,
                    );
                },
            }),
            enterResultsLoading: computed<boolean>(
                () => state[EnterResultsNamespace].loading,
            ),
            unitStandardList: computed<ITrainingPlanUnitStandard[]>(
                () => state[EnterResultsNamespace].unitStandardList,
            ),
            enteredUnitStandardList: computed<ITrainingPlanUnitStandard[]>(
                () => state[EnterResultsNamespace].enteredUnitStandardList,
            ),
            existingUnitStandardList: computed<ITrainingPlanUnitStandard[]>(
                () => state[EnterResultsNamespace].existingUnitStandardList,
            ),
            unitStandardVersions: computed(
                () => state[UnitStandardVersionsNamespace].unitStandardVersions,
            ),

            selectedVersion: computed({
                get: () => state[UnitStandardVersionsNamespace].selectedVersion,
                set: (value) => {
                    commit(
                        `${UnitStandardVersionsNamespace}/${UnitStandardVersionsMutations.setSelectedUnitStandardVersion}`,
                        value,
                    );
                },
            }),

            dateAchieved: computed<string>({
                get: () => state[CreateResultNamespace].dateAchieved,
                set: (value) => {
                    context.root.$store.commit(
                        `${CreateResultNamespace}/${CreateResultMutations.setDateAchieved}`,
                        value,
                    );
                },
            }),

            searchDebtorNumber: null as string | null,
            isValidDebtorNumber: true,

            debtorNumberLoading: computed<boolean>(
                () => state[AccountNamespace].loading,
            ),

            searchedAccountResult: computed<IAccountDto | null>({
                get: () => state[AccountNamespace].searchedAccount,
                set: (value) => {
                    commit(
                        `${AccountNamespace}/${AccountMutations.setSearchedAccount}`,
                        value,
                    );
                },
            }),

            poNumber: computed<string>({
                get: () => state[CreateResultNamespace].poNumber,
                set: (value) => {
                    context.root.$store.commit(
                        `${CreateResultNamespace}/${CreateResultMutations.setPoNumber}`,
                        value,
                    );
                },
            }),
            casualCreditValid: false,
            trainingPlansValid: false,
            casualCreditDialog: false,
            trainingPlanDialog: false,
            saveConfirmLoading: computed<boolean>(
                () => state[CreateResultNamespace].loading,
            ),
            isAlreadySubmitted: false,
            saveError: computed(() => state[CreateResultNamespace].error),
        });
        const isSuccessfullySaved = computed<boolean>(
            () =>
                step3State.isAlreadySubmitted &&
                (step3State.saveError === '' ||
                    step3State.saveError === undefined),
        );

        //#endregion

        //#region methods
        const selectTraineeRow = (item: TrainingPlanVm) => {
            step1State.selectedTrainee = item;
            currentStep.value = 2;
        };

        function selectTrainingPlanRow(item: TrainingPlanVm) {
            step2State.selectedTrainingPlan = item;
            currentStep.value = 3;
        }

        function changeTrainee() {
            step1State.selectedTrainee = null;
            step2State.selectedTrainingPlan = null;
            step2State.showCasualCredit = false;
            step3State.selectedUnitStandard = null;
            currentStep.value = 1;
        }

        function changeTrainingPlan() {
            step2State.selectedTrainingPlan = null;
            step2State.showCasualCredit = false;
            step3State.selectedUnitStandard = null;
            currentStep.value = 2;
        }

        function addCausalCredit() {
            step2State.showCasualCredit = true;
            step3State.selectedUnitStandard = null;
        }

        function changeUnitStandard() {
            step3State.selectedUnitStandard = null;
            currentStep.value = 2;
        }

        function selectUnitStandardRow(item: IUnitStandardVm) {
            step3State.selectedUnitStandard = item;
            currentStep.value = 3;
        }

        const handleCasualCreditConfirmClose = () => {
            step3State.casualCreditDialog = false;
            if (isSuccessfullySaved.value) {
                context.root.$router.go(0);
            }
        };
        const handleCasualCreditConfirm = async () => {
            // tell API to set as Casual Credit
            commit(
                `${CreateResultNamespace}/${CreateResultMutations.setCasualCredit}`,
                true,
            );

            // hit API
            await context.root.$store.dispatch(
                `${CreateResultNamespace}/${CreateResultActions.postCasualCreditToApi}`,
            );

            step3State.isAlreadySubmitted = true;

        };

        const handleCasualCreditEnterResultsClicked = async () => {
            if (
                step3State.searchDebtorNumber &&
                step3State.searchDebtorNumber.length > 0
            ) {
                await performDebtorNumberSearch(
                    step3State.searchDebtorNumber ?? null,
                );
                step3State.isValidDebtorNumber =
                    state[AccountNamespace].searchedAccount != null;
            } else {
                step3State.isValidDebtorNumber = true;
            }
            commit(
                `${CreateResultNamespace}/${CreateResultMutations.setError}`,
                '',
            );

            const test = casualCreditForm.value?.validate();
            if (test && step3State.isValidDebtorNumber) {
                step3State.casualCreditDialog = true;
                step3State.isAlreadySubmitted = false;
            }
        };

        const handleTrainingPlanEntered = () => {
            const isTrainingValid = trainingPlanForm.value?.validate();
            commit(
                `${EnterResultsNamespace}/${EnterResultsMutation.setEnteredUnitStandardList}`,
                step3State.unitStandardList,
            );
            commit(
                `${CreateResultNamespace}/${CreateResultMutations.setError}`,
                '',
            );

            if (isTrainingValid) {
                step3State.trainingPlanDialog = true;
                step3State.isAlreadySubmitted = false;
            }
        };

        const performTraineeSearch = async (searchText: string | null) => {
            if (searchText != null && searchText.trim().length > 0) {
                step1State.showSearchResult = true;
                await context.root.$store.dispatch(
                    `${TrainingPlansNamespace}/${TrainingPlansActions.searchTrainingPlans}`,
                    {
                        searchText: searchText
                    },
                );
            } else {
                clearSearch();
            }
        };

        const performUnitStandardSearch = async (searchCode: string | null) => {
            if (searchCode != null && searchCode.trim().length > 0) {
                await context.root.$store.dispatch(
                    `${UnitStandardNamespace}/${UnitStandardActions.fetchUnitStandard}`,
                    searchCode,
                );
            } else {
                clearSearch();
            }
        };

        const performTrainingPlanUnitStandardFetch = async (
            trainingPlanId: string,
        ) => {
            await context.root.$store.dispatch(
                `${EnterResultsNamespace}/${EnterResultsActions.fetchTrainingPlanUnitStandard}`,
                trainingPlanId,
            );
        };

        const performUnitStandardVersionSearch = async (
            searchCode: string | null,
        ) => {
            if (searchCode != null && searchCode.trim().length > 0) {
                await context.root.$store.dispatch(
                    `${UnitStandardVersionsNamespace}/${UnitStandardVersionsActions.fetchUnitStandardVersions}`,
                    searchCode,
                );
            }
        };

        const performDebtorNumberSearch = async (searchCode: string | null) => {
            if (searchCode != null && searchCode.trim().length > 0) {
                await context.root.$store.dispatch(
                    `${AccountNamespace}/${AccountActions.fetchDebtorNumber}`,
                    searchCode,
                );
            }
        };

        const clearSearch = () => {
            step1State.showSearchResult = false;
            commit(
                `${UnitStandardNamespace}/${UnitStandardMutations.setUnitStandardCodeToSearch}`,
                '',
            );
        };

        const handleTrainingPlanConfirmClose = () => {
            step3State.trainingPlanDialog = false;
            if (isSuccessfullySaved.value) {
                context.root.$router.go(0);
            }
        };
        const handleTrainingPlanConfirm = async () => {
            // tell API to set as Training Plan
            commit(
                `${CreateResultNamespace}/${CreateResultMutations.setCasualCredit}`,
                false,
            );
            // hit api
            await context.root.$store.dispatch(
                `${CreateResultNamespace}/${CreateResultActions.postTrainingPlanToApi}`,
            );

            const unitStandardCreatedResult: ITrainingPlanUnitStandardVm[] =
                context.root.$store.getters[
                    `${CreateResultNamespace}/${CreateResultGetters.getResultCreatedResponses}`
                ];

            if (unitStandardCreatedResult?.length > 0) {
                commit(
                    `${EnterResultsNamespace}/${EnterResultsMutation.setEnteredUnitStandardList}`,
                    unitStandardCreatedResult,
                );
            }
            step3State.isAlreadySubmitted = true;

        };
        const createTrainee = async (data: FormData) => {
            step1State.traineeCreationError = [];
            await context.root.$store.dispatch(
                `${TraineeNamespace}/${TraineeActions.createNewTrainee}`,
                data,
            );
            const newTrainee: Contact | null =
                context.root.$store.getters[
                    `${TraineeNamespace}/${TraineeGetters.getNewTrainee}`
                ];
            if (newTrainee != null) {
                let newTraineeTrainingPlan = {
                    name: `${newTrainee.firstName} ${newTrainee.lastName}`,
                    nSN: null,
                    trainingPlanID: null,
                    programme: null,
                    contactId: newTrainee.id,
                    actions: null,
                    isTtafEligible: null,
                    dateOfBirth: newTrainee.dateOfBirth
                        ? format(new Date(newTrainee.dateOfBirth), 'yyyy-MM-dd')
                        : '',
                    status: null,
                } as unknown as TrainingPlanVm;
                selectTraineeRow(newTraineeTrainingPlan);
            } else {
                try {
                    const error: ApiException | null =
                        context.root.$store.getters[
                            `${TraineeNamespace}/${TraineeGetters.getError}`
                        ];
                    if (error != null) {
                        step1State.traineeCreationError = JSON.parse(
                            JSON.stringify(error.response),
                        ).Message.split('\n');
                    } else {
                        step1State.traineeCreationError.push(
                            'Server Error: Unable to create new trainee. Please try again later',
                        );
                    }
                } catch (e) {
                    step1State.traineeCreationError.push(
                        'Something went wrong while creating new trainee, please contact administration',
                    );
                }
            }
        };

        const onCreateNewTrainee = async () => {
            step1State.showCreateNewTrainee = true;
        };
        //#endregion

        //#region lifecycle
        watch(
            () => step2State.selectedTrainingPlan,
            async (currentValue, previousValue) => {
                if (
                    currentValue?.trainingPlanID ===
                    previousValue?.trainingPlanID
                ) {
                    return;
                }
                if (currentValue != null) {
                    await performTrainingPlanUnitStandardFetch(
                        currentValue.trainingPlanID,
                    );
                }
            },
        );
        watchEffect(() => {
            if (step2State.searchUnitStandardCode?.length === 0) {
                commit(
                    `${UnitStandardNamespace}/${UnitStandardMutations.setUnitStandardCodeToSearch}`,
                    '',
                );
            }
        });
        watch(
            () => step2State.searchUnitStandardCode,
            debounce(async (currentValue, previousValue) => {
                //  if the field hasnt changed even if its been updated, dont search please, loose equality cause numbers and string malarky
                if (currentValue == previousValue) {
                    return;
                }
                // since isNumber returns string or boolean we must explicitly check for true
                if (unitStandardSearchRules.isNumber(currentValue) === true) {
                    // pass in undefined if its null so the search request doesnt have a query string
                    await performUnitStandardSearch(currentValue ?? null);
                    await performUnitStandardVersionSearch(
                        currentValue ?? null,
                    );
                }
            }, 1000),
        );

        onMounted(async () => {
            if (!accessToken.value) {
                await acquireToken();
            }
            // for trainee selected on My Training Plans screen
            // show trainee data under step 1 and skip to step 2 after setting data on step 2
            if (step1State.selectedTrainee != null) {
                context.root.$store.commit(
                    `${TrainingPlansNamespace}/${TrainingPlansMutations.setSearchedPlans}`,
                    [step1State.selectedTrainee],
                );
                currentStep.value = 2;
            }
        });
        //#endregion

        return {
            ...toRefs(step1State),
            ...toRefs(step2State),
            ...toRefs(step3State),
            currentStep,
            casualCreditForm,
            trainingPlanForm,
            // static data
            traineeHeaders: [
                {
                    text: 'Trainee name',
                    align: 'start',
                    value: 'name',
                },
                { text: 'Trainee NSN', value: 'nSN' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            trainingPlansHeaders: [
                {
                    text: 'Training plan ID',
                    align: 'start',
                    value: 'trainingPlanID',
                },
                { text: 'Programme', value: 'programme' },
                { text: 'TTAF', value: 'isTtafEligible' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            unitStandardsHeaders: [
                {
                    text: 'Unit standard',
                    align: 'start',
                    value: 'unitStandardNo',
                },
                {
                    text: 'Description',
                    value: 'description',
                },
                {
                    text: 'Credits',
                    value: 'credits',
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    sortable: false,
                },
            ],
            // validation rules
            unitStandardSearchRules,
            versionRules: [(v: any) => !!v || 'Please enter Version'],
            poNumberRules,
            debtorNumberRules,
            // methods
            performTraineeSearch,
            clearSearch,
            selectTraineeRow,
            changeTrainee,
            changeTrainingPlan,
            changeUnitStandard,
            selectTrainingPlanRow,
            selectUnitStandardRow,
            addCausalCredit,
            handleCasualCreditEnterResultsClicked,
            handleCasualCreditConfirm,
            handleCasualCreditConfirmClose,
            handleTrainingPlanEntered,
            handleTrainingPlanConfirmClose,
            handleTrainingPlanConfirm,
            createTrainee,
            onCreateNewTrainee,
        };
    },
});
