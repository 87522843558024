const GetterNames = Object.freeze({
    accessToken: 'ACCESS_TOKEN',
    signedIn: 'SIGNED_IN',
    name: 'NAME',
});

const MutationNames = Object.freeze({
    setAuthenticationResult: 'SET_AUTHENTICATION_RESULT',
    setInProgress: 'SET_IN_PROGRESS',
    setAccounts: 'SET_ACCOUNTS',
});

export { GetterNames, MutationNames };
