























import { computed, defineComponent } from '@vue/composition-api';
import { VDataTable } from 'vuetify/lib/components';

export default defineComponent({
    name: 'CnDataTable',
    components: {
        VDataTable,
    },
    props: {
        headers: {
            type: Array,
        },
        items: {
            type: Array,
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, context) {
        const parentSlots = computed(() => Object.keys(context.slots));
        return {
            parentSlots,
        };
    },
});
