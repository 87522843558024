import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import { ApiException, ResultClientInstance } from '@/api';
import { IResultVm, toResultVm } from '@/models/ResultVm';

const state = {
    results: [] as IResultVm[],
    loading: false,
    error: null as ApiException | null,
};

export type ResultsEnteredState = typeof state;

const getters: GetterTree<ResultsEnteredState, RootState> = {
    // TODO add getters
};

const mutations: MutationTree<ResultsEnteredState> = {
    [mutationNames.setResults](state, scopes: IResultVm[]) {
        state.results = scopes;
    },
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
};

const actions: ActionTree<ResultsEnteredState, RootState> = {
    async [actionNames.searchForResults](
        context,
        { fromDate, toDate }: { fromDate: string; toDate: string },
    ) {
        try {
            context.commit(mutationNames.setLoading, true);
            const { result } = await ResultClientInstance.get(fromDate, toDate);
            if (result) {
                const resultvms = result.map(toResultVm);
                context.commit(mutationNames.setResults, resultvms);
            }
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<ResultsEnteredState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
