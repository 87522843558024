import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import {
    ApiException,
    IVersionDto,
    UnitStandardVersionClientInstance,
} from '@/api';

const state = {
    // TODO add State
    selectedVersion: null as IVersionDto | null,
    unitStandardVersions: [] as IVersionDto[],
    loading: false,
    error: null as ApiException | null,
};

export type UnitStandardVersionsState = typeof state;

const getters: GetterTree<UnitStandardVersionsState, RootState> = {
    // TODO add getters
};

const mutations: MutationTree<UnitStandardVersionsState> = {
    // TODO add mutations
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setUnitStandardVersions](state, versions: IVersionDto[]) {
        state.unitStandardVersions = versions;
    },
    [mutationNames.setSelectedUnitStandardVersion](
        state,
        version: IVersionDto,
    ) {
        state.selectedVersion = version;
    },
};

const actions: ActionTree<UnitStandardVersionsState, RootState> = {
    // TODO add actions
    async [actionNames.fetchUnitStandardVersions](context, code: number) {
        try {
            const unitStandardVersion =
                await UnitStandardVersionClientInstance.get(code);
            if (unitStandardVersion && unitStandardVersion.result) {
                context.commit(
                    mutationNames.setUnitStandardVersions,
                    unitStandardVersion.result.versions,
                );
            }
        } catch (e) {
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<UnitStandardVersionsState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
