import { TrainingPlanDto } from '@/api/connexis';
import { format } from 'date-fns';

export interface TrainingPlanVm {
    name: string;
    nSN: string;
    trainingPlanID: string;
    programme: string;
    contactId: string;
    actions: string;
    isTtafEligible: boolean;
    dateOfBirth: string;
    status: string;
}

function toTrainingPlanVm(plan: TrainingPlanDto): TrainingPlanVm {
    return {
        name: `${plan.firstName} ${plan.lastName}`,
        nSN: plan.nSN ?? '',
        programme: plan.programmeName ?? '',
        contactId: plan.contactId ?? '',
        trainingPlanID: plan.trainingPlanId ?? '',
        isTtafEligible: plan.isTtafEligible == true,
        dateOfBirth: plan.dateOfBirth
            ? format(new Date(plan.dateOfBirth), 'yyyy-MM-dd')
            : '',
        status: plan.status ?? '',
        actions: 'Enter result',
    };
}

export { toTrainingPlanVm };
