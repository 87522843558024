import { ResultDto } from '@/api';
import { format } from 'date-fns';

export interface IResultVm {
    resultId: string;
    name: string;
    nsn: string;
    unitStandard: string;
    trainingPlan: string;
    dateAchieved: Date | null;
    dateEntered: Date | null;
}

export function toResultVm(result: ResultDto): IResultVm {
    const dateAcieved =
        result.dateAchieved != null ? new Date(result.dateAchieved) : null;
    const dateEntered =
        result.dateEntered != null ? new Date(result.dateEntered) : null;
    return {
        resultId: result.resultId || '',
        name: `${result.traineeFirstName} ${result.traineeLastName}`,
        nsn: result.traineeNSN || '',
        unitStandard: result.frameworkElementCode || '',
        trainingPlan: result.trainingPlanId || '',
        dateAchieved: dateAcieved,
        dateEntered: dateEntered,
    };
}
