const getterNames = Object.freeze({
    // TODO add getter names
    loading: 'GET_LOADING',
    getSearchedAccount: 'GET_SEARCHED_ACCOUNT',
} as const);

const mutationNames = Object.freeze({
    // TODO add mutation names
    setLoading: 'SET_LOADING',
    setError: 'SET_ERROR',
    setSearchedAccount: 'SET_SEARCHED_ACCOUNT',
    setDebtorNumberToSearch: 'SET_DEBTOR_NUMBER_TO_SEARCH',
} as const);

const actionNames = Object.freeze({
    // TODO add action names
    fetchDebtorNumber: 'FETCH_DEBTOR_NUMBER',
} as const);

const namespace = 'accountState';

export { getterNames, mutationNames, actionNames, namespace };
