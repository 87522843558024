import type { GetterTree, MutationTree, ActionTree, Module } from 'vuex';
import type { RootState } from '@/store';
import { actionNames, getterNames, mutationNames, namespace } from './types';
import { ApiException, Contact, TraineeClientinstance } from '@/api';

const state = {
    loading: false,
    error: null as ApiException | null,
    newContact: null as Contact | null,
};

export type TraineeState = typeof state;

const getters: GetterTree<TraineeState, RootState> = {
    [getterNames.getNewTrainee]: (state) => state.newContact,
    [getterNames.getLoading]: (state) => state.loading,
    [getterNames.getError]: (state) => state.error,
};

const mutations: MutationTree<TraineeState> = {
    [mutationNames.setLoading](state, loading: boolean) {
        state.loading = loading;
    },
    [mutationNames.setError](state, error: ApiException) {
        state.error = error;
    },
    [mutationNames.setTrainee](state, trainee: Contact) {
        state.newContact = trainee;
    },
};

const actions: ActionTree<TraineeState, RootState> = {
    async [actionNames.createNewTrainee](context, formData: FormData) {
        try {
            context.commit(mutationNames.setLoading, true);
            const fName = (formData.get('fName') || '').toString();
            const lName = (formData.get('lName') || '').toString();
            const dob = (formData.get('dob') || '').toString();
            const gender = (formData.get('gender') || '').toString();
            const proofFile = formData.get('proofFile') as File;
            const traineeResponse = await TraineeClientinstance.create(
                fName,
                lName,
                dob,
                gender,
                { data: proofFile, fileName: proofFile.name },
            );
            context.commit(mutationNames.setTrainee, traineeResponse.result);
            context.commit(mutationNames.setError, null);
        } catch (e) {
            context.commit(mutationNames.setTrainee, null);
            context.commit(mutationNames.setError, e);
        } finally {
            context.commit(mutationNames.setLoading, false);
        }
    },
};

const module: Module<TraineeState, RootState> = {
    state: () => state,
    getters,
    mutations,
    actions,
    namespaced: true,
};

export { namespace, getterNames, actionNames, mutationNames };
export default module;
