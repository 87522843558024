import { accountIsEqual } from '@/authConfig';
import { MutationNames } from '@/store/types';
import {
    PublicClientApplication,
    AccountInfo,
    InteractionStatus,
} from '@azure/msal-browser';
import { Ref, SetupContext, toRefs, watch } from '@vue/composition-api';
import { isEqualWith } from 'lodash';

interface IMsalState {
    msalInstance: PublicClientApplication | undefined;
    accounts: Ref<AccountInfo[]>;
    inProgress: Ref<InteractionStatus>;
}

/**
 * Provides a component access to the MSAL instance
 * @param context the context from the vue component this is used in.
 */
function useMsal({ root: { $store, $msal } }: SetupContext): IMsalState {
    const { accounts, inProgress } = toRefs($msal);

    watch(
        () => $msal,
        (currentValue, prevValue) => {
            const { inProgress, accounts } = toRefs(currentValue);
            if (inProgress.value !== prevValue?.inProgress) {
                $store.commit(MutationNames.setInProgress, inProgress.value);
            }
            if (
                isEqualWith(accounts.value, prevValue?.accounts, accountIsEqual)
            ) {
                $store.commit(MutationNames.setAccounts, accounts.value);
            }
        },
        { deep: true, immediate: true },
    );

    // handle callback if needed
    if ($msal.inProgress === InteractionStatus.Startup) {
        $msal.instance.handleRedirectPromise().catch(() => {
            // Errors should be handled by listening to the LOGIN_FAILURE event
            return;
        });
    }

    return {
        msalInstance: $msal.instance,
        accounts,
        inProgress,
    };
}

export { useMsal };
